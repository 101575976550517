import React, { MouseEvent } from 'react';
import './index.scss'
import { OfficialProps, OfficialState } from './data'
import { requestSubmitBusinessInfo } from './service'
import { tabbarList, swiperList, productTabbarList, markeRenderList, caseSwiperList, professionOptions, formRenderList, productAPPRenderList, productPCRenderList } from './config'
import { isEmpty, numberFormat } from '@/utils/common';
import { filterInt } from '@/utils/filters'
import { message, Input, Form, Row, Select, Cascader, Button } from 'antd';
import logoImage from '@/assets/sys/official/top_logo.png'
import logoRedImage from '@/assets/sys/official/top_logo_red.png'
import phoneImage from '@/assets/sys/official/iphone2.png'
import pcImage from '@/assets/sys/official/pc.png'
import banner_maskImage from '@/assets/sys/official/banner_mask.png'
import productBgImage from '@/assets/sys/official/product_bg.png'
import cardSelectImage from '@/assets/sys/official/card_select.png'
import cardUnSelectImage from '@/assets/sys/official/card_unSelect.png'
import cityData from '@/utils/cityCode'
const { TextArea } = Input;
const { Option } = Select


export default class Official extends React.Component<OfficialProps, OfficialState> {

    constructor(props: OfficialProps = {}) {
        super(props);
        this.state = {
            slideLeft: 0,
            scrollTop: 0,
            activeTabbar: 0,
            activeSwiper: 0,
            activeProductTabbar: 0,
            formData: {},
            formErrorData: {},
            isLoading: false,
            isClick: false,
            productPCIndex: 0,
            productAPPIndex: 0
        }
    }

    swiper = null

    caseSwiper = null

    heightList = [1118, 1834, 2834, 3526, 4378, 9999]

    componentDidMount() {
        const that = this
        // @ts-ignore #
        this.swiper = new Swiper(".mySwiper", {
            effect: "coverflow",
            slidesPerView: 'auto',
            initialSlide: that.state.activeSwiper,
            loop: true,
            autoplay: true,
            noSwiping: true,
            grabCursor: true,
            slideToClickedSlide: true,
            centeredSlides: true,
            coverflowEffect: {
                rotate: 0,
                stretch: 10,
                slideShadows: false,
            },
            pagination: {
                el: ".swiper-pagination",
            },
            on: {
                slideChange: function (activeIndex: any) {
                    that.setState({
                        // @ts-ignore #
                        activeSwiper: (this.activeIndex % swiperList.length) || 0
                    })
                    //console.log(activeIndex
                },
            },
        });

        // @ts-ignore #
        this.caseSwiper = new Swiper(".case-swiper", {
            loop: true,
            autoplay: true,
            //autoplay: true
            // initialSlide: that.state.activeSwiper,
            // loop: true,
            // grabCursor: true,
            // slideToClickedSlide: true,
            // centeredSlides: true,
            // pagination: {
            //     el: ".swiper-pagination",
            // }
        });

        window.onscroll = (event) => {
            const scrollTop = document.documentElement.scrollTop
            this.setState({
                scrollTop: scrollTop
            })
            if (!this.state.isClick) {
                let activeTabbar = 0, windowHeight = document.documentElement.clientHeight
                for (let i = 0; i < this.heightList.length; i++) {
                    if (scrollTop < this.heightList[i] - (windowHeight / 3 * 2)) {
                        activeTabbar = i
                        break
                    }
                }
                const element: any = this.refs[`tabbarItem-${activeTabbar}`]
                const childrenLeft = element.offsetLeft, childrenWidth = element.offsetWidth
                this.setState({
                    activeTabbar: activeTabbar,
                    slideLeft: childrenLeft + (childrenWidth - 30) / 2,
                })
            }
        }
    }




    // 处理点击tabbar
    handleClick(event: MouseEvent, tabbarKey: number) {
        this.setState({
            isClick: true
        }, () => {
            const element: any = event.target
            const childrenLeft = element.offsetLeft, childrenWidth = element.offsetWidth
            this.setState({
                slideLeft: childrenLeft + (childrenWidth - 30) / 2,
                activeTabbar: tabbarKey
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isClick: false
                    })
                }, 800)
            })
        })
    }

    // 处理点击swiper上一个事件
    handleSwiperPrev() {
        if (this.swiper) {
            (this.swiper as any).slidePrev()
        }

    }

    // 处理优质案例swiper上一个事件
    handleOfficialPrev() {
        if (this.caseSwiper) {
            (this.caseSwiper as any).slidePrev()
        }
    }

    // 处理点击swiper下一个事件
    handleSwiperNext() {
        if (this.swiper) {
            (this.swiper as any).slideNext()
        }
    }

    // 处理优质案例swiper下一个事件
    handleOfficialNext() {
        if (this.caseSwiper) {
            (this.caseSwiper as any).slideNext()
        }
    }

    // 处理点击swiper事件
    handleClickSwiper(swiperKey: number) {
        if (this.swiper) {
            (this.swiper as any).slideTo(swiperKey)
        }
    }

    // 处理产品介绍点击tabbar事件
    handleActiveProductTabbar(tabbarKey: number) {
        this.setState({
            activeProductTabbar: tabbarKey
        })
    }

    // 处理鼠标移入app事件
    handleMouseMovePhone(productKey: number) {
        this.setState({
            productAPPIndex: productKey
        })
    }

    // 处理鼠标移入pc事件
    handleMouseMovePC(productKey: number) {
        this.setState({
            productPCIndex: productKey
        })
    }


    // 渲染斗转APP产品介绍
    renderDZAPP() {
        return <div className='contnent-phone'>
            <img className='phone' src={phoneImage} alt="" />
            {
                productAPPRenderList.map((productItem, productKey) => {
                    return <div className={`phone-detail ${productKey == this.state.productAPPIndex ? 'phone-detail-active' : ''}`} key={productKey}>
                        <img src={productItem.img} alt="" />
                    </div>
                })

            }
            <div className='product-list'>
                {
                    productAPPRenderList.map((productItem, productKey) => {
                        return <div className={`product-item ${productKey == this.state.productAPPIndex ? 'product-item-active' : ''}`} key={productKey} onMouseMove={() => { this.handleMouseMovePhone(productKey) }}>
                            <img src={productItem.icon} alt="" />
                            <div className='product-title'>{productItem.title}</div>
                            <div className='product-subTitle'>{productItem.subTitle}</div>
                            <div className='product-bg'>
                                <img src={cardUnSelectImage} alt="" />
                            </div>
                            {
                                productKey == this.state.productAPPIndex ? <div className='product-bg product-bg-active'>
                                    <img src={cardSelectImage} alt="" />
                                </div> : <div></div>
                            }
                        </div>
                    })
                }
            </div>
        </div>
    }

    // 渲染斗转星图产品介绍
    renderDZPC() {
        return <div className='contnent-pc'>
            <img className='pc' src={pcImage} alt="" />
            {
                productPCRenderList.map((productItem, productKey) => {
                    return <div className={`pc-detail ${productKey == this.state.productPCIndex ? 'pc-detail-active' : ''}`} key={productKey}>
                        <img src={productItem.img} alt="" />
                    </div>
                })

            }
            <div className='product-list'>
                {
                    productPCRenderList.map((productItem, productKey) => {
                        return <div className={`product-item ${productKey == this.state.productPCIndex ? 'product-item-active' : ''}`} key={productKey} onMouseMove={() => { this.handleMouseMovePC(productKey) }}>
                            <img src={productItem.icon} alt="" />
                            <div className='product-title'>{productItem.title}</div>
                            <div className='product-subTitle'>{productItem.subTitle}</div>
                            <div className='product-bg'>
                                <img src={cardUnSelectImage} alt="" />
                            </div>
                            {
                                productKey == this.state.productPCIndex ? <div className='product-bg product-bg-active'>
                                    <img src={cardSelectImage} alt="" />
                                </div> : <div></div>
                            }
                        </div>
                    })
                }
            </div>
        </div>
    }

    // 处理表单内容变化时触发
    handleChange(value: any, type: string, otherData?: any) {
        const formData = { ...this.state.formData }, formErrorData = { ...this.state.formErrorData }
        if (type === 'mobile') {
            value = filterInt(value)
        }
        if (type === 'address') {
            let addressName = ""
            for (let i = 0; i < otherData.length; i++) {
                addressName += `${i > 0 ? '/' : ''}${otherData[i].name}`
            }
            formData.addressName = addressName
        }
        formData[type] = value
        formErrorData[type] = ''
        this.setState({
            formData: formData,
            formErrorData: formErrorData
        })
    }

    // 处理表单提交
    handleSubmit() {
        const formErrorData: any = {}, formData = { ...this.state.formData }
        for (let i = 0; i < formRenderList.length; i++) {
            const formRenderItem = formRenderList[i]
            if (isEmpty(formData[formRenderItem.value])) {
                formErrorData[formRenderItem.value] = `${formRenderItem.placeholder}${formRenderItem.label}`
            } else {
                if (formRenderItem.vaildFnc && typeof formRenderItem.vaildFnc === 'function') {
                    if (!formRenderItem.vaildFnc(formData[formRenderItem.value])) {
                        formErrorData[formRenderItem.value] = `${formRenderItem.placeholder}正确的${formRenderItem.label}`
                    }
                }
            }
        }
        if (isEmpty(formErrorData)) {
            this.setState({
                isLoading: true
            })
            requestSubmitBusinessInfo({
                address: formData.addressName,
                companyName: formData.companyName,
                mobile: formData.mobile,
                productIntroduce: formData.productIntroduce,
                profession: formData.profession,
                userName: formData.userName
            }).then(() => {
                message.success('提交成功')
                this.setState({
                    formData: {},
                    isLoading: false
                })
            }).catch(() => {
                this.setState({
                    isLoading: false
                })
            })
        } else {
            this.setState({
                formErrorData: formErrorData
            })
        }

    }

    // 处理点击查看详情
    handleviewDetail() {
        if (this.state.activeProductTabbar === 0) {
            window.open('https://appstatic.douzhuan.cn/douzhuanHyw/spread/', '_blank')
        } else {
            window.open('http://xingtu.douzhuan.cn/', '_blank')
        }
    }


    render() {
        return (
            <div className="official">
                <div className='official-navbar official-navbar-next' style={{ opacity: `${this.state.scrollTop / 70}` }}>
                    <img className='official-navbar-logo' src={logoRedImage} alt="" />
                    <div className='official-navbar-tabbar'>
                        {
                            tabbarList.map((tabbarItem, tabbarKey) => {
                                return <a className={`${this.state.activeTabbar === tabbarKey ? 'tabItem-active' : 'tabItem'}`} ref={`tabbarItem-${tabbarKey}`} href={tabbarItem.href} onClick={(event) => { this.handleClick(event, tabbarKey) }} key={tabbarKey}>{tabbarItem.label}</a>
                            })
                        }
                        <div style={{ transform: `translateX(${this.state.slideLeft}px)` }} className='tabbar-slide tabbar-slide-next'></div>
                    </div>
                </div>

                <div className='official-navbar' style={{ opacity: `${1 - this.state.scrollTop / 70 < 0 ? 0 : 1 - this.state.scrollTop / 70}` }}>
                    <img className='official-navbar-logo' src={logoImage} alt="" />
                    <div className='official-navbar-tabbar'>
                        {
                            tabbarList.map((tabbarItem, tabbarKey) => {
                                return <a className='tabItem-top-up' ref={`tabbarItem-${tabbarKey}`} href={tabbarItem.href} onClick={(event) => { this.handleClick(event, tabbarKey) }} key={tabbarKey}>{tabbarItem.label}</a>
                            })
                        }
                        <div style={{ transform: `translateX(${this.state.slideLeft}px)` }} className='tabbar-slide tabbar-slide-up'></div>
                    </div>
                </div>
                <div className='official-header'>
                    <span className='official-header-title'>斗转一下美好生活</span>
                    <span className='official-header-subTitle'>让内容变得更有价值</span>
                    {/* <img className='official-header-img' src={bannerImage} alt="" /> */}
                    <img className='official-header-mask' src={banner_maskImage} alt="" />
                </div>
                <div className='official-about'>
                    <div className='watermark'>ABOUT</div>
                    <div className="title">关于斗转</div>
                    <div className='content'>
                        <div>品牌商、创作者、内容媒体的价值连接器。</div>
                        <div> 基于创作者生态，为品牌带来远低于行业成本的流量、声量、销量资源。为创作者提供更丰富、更优质的内容生产服务和变现能力。 </div>
                        <div>为内容媒体培养更优质的创作者和更有价值的内容。链接三方，服务三方，成就三方。</div>
                    </div>
                    <div className='bottom'>
                        <div className='bottom-item'>
                            <div className='bottom-item-top'>
                                <span>100+</span>
                            </div>
                            <div className='bottom-item-bottom'>合作品牌IP</div>
                        </div>
                        <div className='bottom-item'>
                            <div className='bottom-item-top'>
                                <span>5</span>
                                <span>亿</span>
                                <span>+</span>
                            </div>
                            <div className='bottom-item-bottom'>累计播放量</div>
                        </div>
                        <div className='bottom-item'>
                            <div className='bottom-item-top'>
                                <span>5000</span>
                                <span>w</span>
                                <span>+</span>
                            </div>
                            <div className='bottom-item-bottom'>累计粉丝量</div>
                        </div>
                        <div className='bottom-item'>
                            <div className='bottom-item-top'>
                                <span>10000+</span>
                            </div>
                            <div className='bottom-item-bottom'>入驻达人</div>
                        </div>
                    </div>
                </div>

                <div className='official-advantage' id='advantage'>
                    <div className='watermark'>ADVANTAGE</div>
                    <div className='title'>平台优势</div>
                    <div className='content'>
                        <div className='control'>
                            <div className='control-page'>
                                <span>{numberFormat(this.state.activeSwiper + 1)}</span>
                                <span>/{numberFormat(swiperList.length)}</span>
                            </div>
                            <div className='control-title'>{swiperList[this.state.activeSwiper].title}</div>
                            <div className='control-subTitle'>{swiperList[this.state.activeSwiper].subTitle}</div>
                            <div className='control-content'>
                                <div className='control-prev' onClick={() => { this.handleSwiperPrev() }}>
                                    <svg style={{ transform: `rotate(180deg)` }} id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><title>箭头</title><polygon fill="#7b7c7d" points="181.08 154.33 136.94 180.81 145.76 154.33 136.94 127.84 181.08 154.33"></polygon></svg>
                                </div>
                                <div className='control-next' onClick={() => { this.handleSwiperNext() }}>
                                    <svg id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><title>箭头</title><polygon fill="#7b7c7d" points="181.08 154.33 136.94 180.81 145.76 154.33 136.94 127.84 181.08 154.33"></polygon></svg>
                                </div>
                            </div>
                        </div>
                        <div className='swiper mySwiper'>
                            <div className="swiper-wrapper" >
                                {
                                    swiperList.map((swiperItem, swiperKey) => {
                                        return <div key={swiperKey} className={`swiper-slide ${swiperKey === this.state.activeSwiper ? 'swiper-slide-active' : ''}`}>
                                            <img src={swiperItem.img} />
                                            <div className={`swiper-slide-watermark`}></div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='official-product' id='product'>
                    <div className='watermark'>INTRODUCTION</div>
                    <div className='title'>产品介绍</div>
                    <div className='official-product-bg'>
                        <img src={productBgImage} alt="" />
                    </div>
                    <div className='official-product-tabbar'>
                        <div className='official-product-tabba-slide' style={{ transform: `translateX(${this.state.activeProductTabbar * 132 + 2}px)` }}></div>
                        {
                            productTabbarList.map((tabbarItem, tabbarKey) => {
                                return <div key={tabbarKey} className='official-product-tabbar-item' onClick={() => { this.handleActiveProductTabbar(tabbarKey) }}>{tabbarItem.label}</div>
                            })
                        }
                    </div>
                    {

                        this.state.activeProductTabbar === 0 ? this.renderDZAPP() : this.renderDZPC()
                    }
                    <div style={{ display: `${this.state.activeProductTabbar === 0 ? 'block' : 'none'}` }}>
                        {
                            this.renderDZAPP()
                        }
                    </div>
                    <div style={{ display: `${this.state.activeProductTabbar === 0 ? 'none' : 'block'}` }}>
                        {
                            this.renderDZPC()
                        }
                    </div>
                    <div className='official-product-viewDetail' onClick={() => { this.handleviewDetail() }}>查看详情</div>
                </div>
                <div className='official-marke' id='marke'>
                    <div className='watermark'>MARKETING</div>
                    <div className='title'>营销能力</div>
                    <div className='official-marke-list'>
                        {
                            markeRenderList.map((markItem, markKey) => {
                                return <div className='official-marke-item' key={markKey}>
                                    <img className='official-marke-item-img' src={markItem.image} alt="" />
                                    <div className='official-marke-item-content'>
                                        <img className='official-marke-item-serialImage' src={markItem.serialImage} alt="" />
                                        <div className='official-marke-item-title'>{markItem.title}</div>
                                        <div className='official-marke-item-subTitle'>{markItem.subTitle}</div>
                                    </div>
                                    <div className='official-marke-item-overlay'></div>

                                </div>
                            })
                        }
                    </div>
                </div>
                <div className='official-case' id='case'>
                    <div className='watermark'>CASE</div>
                    <div className='title'>优质案例</div>
                    <div className='official-case-content'>
                        <div className='case-swiper swiper'>
                            <div className="swiper-wrapper">
                                {
                                    caseSwiperList.map((swiperItem, swiperKey) => {
                                        return <div className='case-swiper-item swiper-slide' key={swiperKey}>
                                            <img className='case-swiper-item-img' src={swiperItem.img} alt="" />
                                            <div className='case-swiper-item-content'>
                                                <div className='case-swiper-item-title'>
                                                    <span>{swiperItem.title}</span>
                                                    <div className='case-swiper-item-tip'>品牌定制</div>
                                                    <div className='case-swiper-item-slide'></div>
                                                </div>
                                                <div className='case-swiper-item-subTitle'>{swiperItem.subTitle}</div>
                                                <div className='case-swiper-item-detail'>
                                                    <div className='case-swiper-item-play'>
                                                        <div className='case-swiper-item-play-data'>
                                                            <span>{swiperItem.playNum}</span>
                                                            <span>w</span>
                                                            <span>+</span>
                                                        </div>
                                                        <div>累计播放量</div>
                                                    </div>
                                                    <div className='case-swiper-item-num'>
                                                        <div>{swiperItem.peopleNum}+</div>
                                                        <div>累计参与人数</div>
                                                    </div>
                                                    <div className='case-swiper-item-num'>
                                                        <div>{swiperItem.productionNum}+</div>
                                                        <div>累计产生作品数</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className='official-case-prev' onClick={() => { this.handleOfficialPrev() }}>
                            <svg style={{ transform: `rotate(180deg)` }} data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><title>箭头</title><polygon fill="#7b7c7d" points="181.08 154.33 136.94 180.81 145.76 154.33 136.94 127.84 181.08 154.33"></polygon></svg>
                        </div>
                        <div className='official-case-next' onClick={() => { this.handleOfficialNext() }}>
                            <svg data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><title>箭头</title><polygon fill="#7b7c7d" points="181.08 154.33 136.94 180.81 145.76 154.33 136.94 127.84 181.08 154.33"></polygon></svg>
                        </div>
                    </div>
                </div>

                <div className='official-cooperation' id='cooperation'>
                    <div className='watermark'>COOPERATION</div>
                    <div className='title'>商务合作</div>
                    <div className='official-cooperation-content'>
                        <Form name="horizontal_login" layout="inline" form={this.state.formData}>
                            <Row>
                                <Form.Item style={{ width: '356px', marginRight: '16px', position: 'relative' }}>
                                    <Input placeholder="企业名称" value={this.state.formData.companyName} onChange={(event) => { this.handleChange(event.target.value, 'companyName') }} maxLength={100} />
                                    <span className='official-cooperation-form-error'>{this.state.formErrorData.companyName}</span>
                                </Form.Item>

                                <Form.Item style={{ width: '356px', marginRight: '16px', position: 'relative' }}>
                                    <Input placeholder="姓名" value={this.state.formData.userName} onChange={(event: any) => { this.handleChange(event.target.value, 'userName') }} maxLength={50} />
                                    <span className='official-cooperation-form-error'>{this.state.formErrorData.userName}</span>
                                </Form.Item>

                                <Form.Item style={{ width: '356px', marginRight: 0, position: 'relative' }}>
                                    <Input maxLength={11} placeholder="手机号" value={this.state.formData.mobile} onChange={(event: any) => { this.handleChange(event.target.value, 'mobile') }} />
                                    <span className='official-cooperation-form-error'>{this.state.formErrorData.mobile}</span>
                                </Form.Item>
                            </Row>

                            <Row style={{ marginTop: '20px' }}>
                                <Form.Item style={{ width: '356px', marginRight: '16px', position: 'relative' }}>
                                    <Cascader placeholder='地区' options={cityData} fieldNames={{ label: 'name', value: 'code', children: 'child' }} value={this.state.formData.address} onChange={(event: any, selectedOptions) => { this.handleChange(event, 'address', selectedOptions) }}></Cascader>
                                    <span className='official-cooperation-form-error'>{this.state.formErrorData.address}</span>
                                </Form.Item>
                                <Form.Item style={{ width: '356px', marginRight: '16px', position: 'relative' }}>
                                    <Select placeholder="行业" value={this.state.formData.profession} onSelect={(event: any) => { this.handleChange(event, 'profession') }}>
                                        {
                                            professionOptions.map((item, key) => {
                                                return <Option key={key} value={item.value}>{item.label}</Option>
                                            })
                                        }
                                    </Select>
                                    <span className='official-cooperation-form-error'>{this.state.formErrorData.profession}</span>
                                </Form.Item>
                            </Row>

                            <Row style={{ marginTop: '20px' }}>
                                <Form.Item style={{ width: '1100px', marginRight: '16px', position: 'relative' }}>
                                    <TextArea placeholder='推广产品介绍' style={{ height: '120px' }} value={this.state.formData.productIntroduce} onChange={(event: any) => { this.handleChange(event.target.value, 'productIntroduce') }} maxLength={1000}></TextArea>
                                    <span className='official-cooperation-form-error'>{this.state.formErrorData.productIntroduce}</span>
                                </Form.Item>
                            </Row>

                            <Row style={{ marginTop: '60px' }}>
                                <Button loading={this.state.isLoading} className='official-cooperation-submit' onClick={() => { this.handleSubmit() }}>立即提交</Button>
                            </Row>

                        </Form>
                    </div>
                </div>
                <footer className='official-footer'>
                    <div className='official-footer-content'>
                        <span className='official-footer-email'>联系邮箱：douzhuan@douzhuan.cn</span>
                        <span className='official-footer-address'>地址：北京市大兴区亦庄镇荣华南路2号院大族广场T6号楼2202</span>
                        <span className='official-footer-company'>Copyright © 2022 北京宏宇微网络技术有限公司</span>
                        <img className='official-footer-logo' src={logoImage} alt="" />
                    </div>

                </footer>
            </div>
        )
    }
}